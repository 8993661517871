import * as React from 'react'
import Layout from '../components/Layout'
import Shop from '../components/Shop'

const ShopPage = () =>
  <Layout>
    <Shop />
  </Layout>

export default ShopPage
